:root {
  --font-inter: "Inter", sans-serif;
}

:root {
  --limegreen: hsl(163, 72%, 41%);
  --brightred: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --white-bg: hsl(0, 0%, 100%);
  --very-pale-blue-top-bg: hsl(225, 100%, 98%);
  --light-grayish-blue-card: hsl(227, 47%, 96%);
  --light-grayish-blue-card-hover: hsl(231, 25%, 90%);
  --dark-grayish-blue-text: hsl(228, 12%, 44%);
  --very-dark-blue-text: hsl(230, 17%, 14%);
  --light-card-bg: hsl(0.62 0.50 0.96);
  --very-dark-blue-bg: hsl(230, 17%, 14%);
  --very-dark-blue-top-bg: hsl(232, 19%, 15%);
  --dark-desaturated-blue-card-bg: hsl(228, 28%, 20%);
  --dark-desaturated-blue-card-bg-hover: hsl(229, 28%, 27%);
  --desaturated-blue-text: hsl(228, 34%, 66%);
  --white-text: hsl(0, 0%, 100%);
  --toggle: hsl(230, 22%, 74%);
  --toggle-light-start: hsl(210, 78%, 56%);
  --toggle-light-end: hsl(146, 68%, 55%);
  --counter-grayish-blue: hsl(237, 18%, 59%);
  --counter-soft-red: hsl(345, 95%, 68%);
  --counter-white: hsl(0, 0%, 100%);
  --counter-dark-desaturated-blue: hsl(236, 21%, 26%);
  --counter-very-dark-blue: hsl(235, 16%, 14%);
  --counter-very-dark-black-blue: hsl(234, 17%, 12%);
  --counter-shadow-blue: hsl(235, 20%, 32%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
}

h1, h2, h3 {
  margin-top: 0;
}

a, a:hover, a:visited, a:active, a:focus {
  text-decoration: none;
}

.container {
  max-width: 69.375rem;
  margin: 0 auto;
}

#fem-smd:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 15.625rem;
  border-bottom-left-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
}

#fem-smd.dark-theme-section:before,
#fem-smd:before {
  background-color: var(--very-dark-blue-top-bg);
}
#fem-smd.dark-theme-section .card,
#fem-smd .card {
  background-color: var(--dark-desaturated-blue-card-bg);
}
#fem-smd.dark-theme-section .card:hover,
#fem-smd .card:hover {
  background-color: var(--dark-desaturated-blue-card-bg-hover);
  cursor: pointer;
}

#fem-smd.light-theme-section {
  background-color: var(--white-bg);
  color: var(--very-dark-blue-text);
}
#fem-smd.light-theme-section .followers-count {
  color: var(--dark-grayish-blue-text);
}
#fem-smd.light-theme-section:before {
  background-color: var(--very-pale-blue-top-bg);
}
#fem-smd.light-theme-section .card {
  background-color: var(--light-grayish-blue-card);
}
#fem-smd.light-theme-section .card:hover {
  background-color: var(--light-grayish-blue-card-hover);
  cursor: pointer;
}
#fem-smd.light-theme-section .card .card-big-top p {
  color: var(--dark-grayish-blue-text);
}
#fem-smd.light-theme-section .card .views-label {
  color: var(--dark-grayish-blue-text);
}

.card {
  border-radius: 0.3125rem;
  padding: 1.5625rem;
  position: relative;
}
.card .views img {
  width: 0.5rem;
  height: 0.3125rem;
  margin-right: 0.25rem;
}
.card .views.down img {
  margin-top: 0.0625rem;
}

.section-totals {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-top: 3.75rem;
}
@media (min-width: 71.875em) {
  .section-totals {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 71.8125em) {
  .section-totals {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 31.25em) {
  .section-totals {
    grid-template-columns: repeat(1, 1fr);
  }
}
.section-totals .card-big {
  text-align: center;
}
@media (max-width: 71.8125em) {
  .section-totals .card-big {
    grid-template-columns: repeat(2, 1fr);
    width: auto;
  }
}
@media (max-width: 31.25em) {
  .section-totals .card-big {
    margin: 0 auto;
    width: 100%;
  }
}
.section-totals .card-big.facebook {
  border-top: 0.3125rem solid var(--facebook);
}
.section-totals .card-big.twitter {
  border-top: 0.3125rem solid var(--twitter);
}
.section-totals .card-big.instagram {
  position: relative;
  overflow: hidden;
}
.section-totals .card-big.instagram::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0.3125rem;
  background: linear-gradient(225deg, var(--instagram-end) 0%, var(--instagram-start) 98.02%);
}
.section-totals .card-big.youtube {
  border-top: 0.3125rem solid var(--youtube);
}
.section-totals .card-big .card-big-top {
  display: inline-flex;
}
.section-totals .card-big .card-big-top img {
  align-self: center;
  margin-right: 0.3125rem;
}
.section-totals .card-big .card-big-top p {
  margin: 0;
  font-size: 0.75rem;
  align-self: center;
  line-height: 1;
  font-weight: 700;
  color: var(--desaturated-blue-text);
}
.section-totals .card-big .card-big-middle {
  padding-top: 1.25rem;
}
.section-totals .card-big .card-big-middle .count-big {
  font-size: 2.875rem;
  font-weight: 700;
}
.section-totals .card-big .card-big-middle p {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.3125rem;
  font-size: 0.625rem;
  color: var(--desaturated-blue-text);
}
.section-totals .card-big .card-big-bottom {
  display: inline-flex;
  padding-top: 1.25rem;
  font-size: 0.75rem;
}
.section-totals .card-big .card-big-bottom img {
  align-self: center;
  margin-right: 0.1875rem;
}
.section-totals .card-big .card-big-bottom p {
  font-weight: 700;
}
.section-totals .card-big .card-big-bottom.up {
  color: var(--limegreen);
}
.section-totals .card-big .card-big-bottom.up img {
  fill: var(--limegreen);
}
.section-totals .card-big .card-big-bottom.down {
  color: var(--brightred);
}
.section-totals .card-big .card-big-bottom.down img {
  fill: var(--brightred);
}

.section-daily {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.875rem;
}
@media (max-width: 71.8125em) {
  .section-daily {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
@media (max-width: 31.25em) {
  .section-daily {
    grid-template-columns: repeat(1, 1fr);
  }
}
.section-daily .card-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.25rem;
  flex-wrap: wrap;
  height: 7.75rem;
}
@media (max-width: 71.8125em) {
  .section-daily .card-small {
    width: 100%;
    margin: 0;
  }
}
.section-daily .card-small .views-percentage,
.section-daily .card-small .page-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section-daily .card-small .views-percentage p,
.section-daily .card-small .page-view p {
  margin: 0;
}
.section-daily .card-small .page-view .views-label {
  font-size: 0.8125rem;
  color: var(--desaturated-blue-text);
  font-weight: 700;
}
.section-daily .card-small .page-view p {
  font-size: 2rem;
  font-weight: 700;
  line-height: 0.9;
}
.section-daily .card-small .views-percentage > img {
  width: 1.25rem;
  align-self: end;
}
.section-daily .card-small .views-percentage .views {
  display: flex;
  font-size: 0.75rem;
  font-weight: 700;
}
.section-daily .card-small .views-percentage .views.up {
  color: var(--limegreen);
}
.section-daily .card-small .views-percentage .views.down {
  color: var(--brightred);
}
.section-daily .card-small .views-percentage .views img {
  align-self: center;
}

.toggle-title {
  font-size: 0.8125rem;
  line-height: 100%;
  display: flex;
  align-self: center;
  padding-right: 7px;
  font-weight: 700;
  color: var(--desaturated-blue-text);
}

.toggle-wrapper {
  position: relative;
  display: inline-block;
  width: 3.25rem;
  height: 1.5625rem;
}
.toggle-wrapper input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-wrapper .toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2.125rem;
  background: linear-gradient(225deg, var(--toggle-light-end) 0%, var(--toggle-light-start) 98.02%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggle-wrapper .toggle:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 0.1875rem;
  top: 0.15625rem;
  border-radius: 50%;
  background-color: var(--very-dark-blue-bg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.toggle-wrapper input:checked + .toggle:before {
  -webkit-transform: translateX(1.625rem);
  -ms-transform: translateX(1.625rem);
  transform: translateX(1.625rem);
}

input:checked + .toggle:before {
  background: var(--white-bg);
}

input:checked + .toggle {
  background: linear-gradient(225deg, var(--toggle) 0%, var(--toggle) 98.02%);
}

#fem-counter {
  position: relative;
  background-image: url(../../../images/bg-stars.svg);
  background-color: var(--counter-very-dark-blue);
  background-size: contain;
  background-repeat: no-repeat;
  font-family: "Red Hat Text", sans-serif;
  position: relative;
  padding: 160px 0 0;
}
@media (max-width: 31.25em) {
  #fem-counter {
    background-position: 49% 20px;
    background-size: auto;
  }
}
#fem-counter::after {
  content: "";
  background-image: url(../../../images/pattern-hills.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 11.75rem;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 31.25em) {
  #fem-counter::after {
    height: 9.75rem;
    left: -645px;
  }
}
#fem-counter h2 {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 12px;
  text-indent: 12px;
}
@media (max-width: 71.8125em) {
  #fem-counter h2 {
    font-size: 1.125rem;
  }
}
@media (max-width: 31.25em) {
  #fem-counter h2 {
    font-size: 1rem;
    letter-spacing: 4px;
    padding: 0 40px;
  }
}
#fem-counter .counter-social-icons {
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 1;
  padding: 50px;
}
@media (max-width: 31.25em) {
  #fem-counter .counter-social-icons {
    padding: 25px;
  }
}
#fem-counter .counter-social-icons .icon {
  margin: 0 15px;
  cursor: pointer;
}
#fem-counter .counter-social-icons .icon:hover {
  filter: invert(52%) sepia(31%) saturate(1304%) hue-rotate(299deg) brightness(100%) contrast(97%);
}

.counter-wrapper {
  padding: 115px 0 250px;
}
@media (max-width: 31.25em) {
  .counter-wrapper {
    padding: 70px 0 250px;
  }
}
.counter-wrapper .counter__numbers {
  text-align: center;
  color: var(--counter-soft-red);
  font-weight: 700;
  font-size: 5.3125rem;
}
@media (max-width: 71.8125em) {
  .counter-wrapper .counter__numbers {
    font-size: 3.3125rem;
  }
}
@media (max-width: 31.25em) {
  .counter-wrapper .counter__numbers {
    font-size: 2.0625rem;
  }
}
.counter-wrapper .counter__numbers .count-num {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.25rem;
  background: linear-gradient(0deg, var(--counter-shadow-blue) 49%, var(--counter-dark-desaturated-blue) 48%);
  padding: 0.9375rem;
  border-radius: 8px;
  -webkit-box-shadow: 0px 8px 7px 1px #000000;
  box-shadow: 0px 8px 7px 1px #000000;
  width: 9.1875rem;
  height: 8.75rem;
}
@media (max-width: 71.8125em) {
  .counter-wrapper .counter__numbers .count-num {
    width: 6.1875rem;
    height: 5.75rem;
    margin: 0 0.625rem;
  }
}
@media (max-width: 31.25em) {
  .counter-wrapper .counter__numbers .count-num {
    margin: 0 5px;
    width: 65px;
    height: 58px;
  }
}
.counter-wrapper .counter__numbers .count-num::before {
  content: "";
  position: absolute;
  height: 2px;
  background-color: rgba(41, 37, 37, 0.507);
  top: 50%;
  left: 0;
  right: 0;
  display: block;
  box-shadow: 0px 1px 0px 0px rgba(92, 92, 92, 0.2);
}
.counter-wrapper .counter__numbers .count-num .count-num-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-44%);
  height: 10px;
  width: 6px;
  background-color: var(--counter-very-dark-black-blue);
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px;
}
.counter-wrapper .counter__numbers .count-num .count-num-inner::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-44%);
  height: 10px;
  width: 6px;
  background-color: var(--counter-very-dark-black-blue);
  border-bottom-left-radius: 60px;
  border-top-left-radius: 60px;
}
.counter-wrapper .counter__numbers .count-num .count-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.375rem;
  text-indent: 6px;
  color: var(--counter-grayish-blue);
}
@media (max-width: 31.25em) {
  .counter-wrapper .counter__numbers .count-num .count-text {
    font-size: 0.4347rem;
    bottom: -1.5rem;
    letter-spacing: 0.15rem;
  }
}

#fem-smd {
  padding: 2.5rem 0.9375rem;
  background-color: var(--very-dark-blue-bg);
  color: var(--white-text);
}

.section-heading {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 31.25em) {
  .section-heading {
    display: block;
  }
  .section-heading__col-left {
    border-bottom: 0.0625rem solid var(--dark-grayish-blue-text);
    padding-bottom: 1.25rem;
  }
  .section-heading__col-right {
    display: flex;
    justify-content: space-between;
  }
}
.section-heading h1 {
  margin-bottom: 0;
}
.section-heading .followers-count {
  font-size: 0.8125rem;
  color: var(--desaturated-blue-text);
  font-weight: 700;
}
.section-heading .section-heading__col-right {
  display: flex;
}
@media (max-width: 31.25em) {
  .section-heading .section-heading__col-right {
    margin-top: 20px;
  }
}

.sub-title {
  margin-top: 3.75rem;
}
.sub-title h2 {
  margin-bottom: 0.625rem;
}

.credits {
  margin-top: 3.75rem;
  font-size: 14px;
  line-height: 1;
  color: var(--desaturated-blue-text);
}
.credits p {
  margin: 0.625rem 0;
}