@use '../util/' as *;

// Partial background
#fem-smd {

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: rem(250);
        border-bottom-left-radius: rem(15);
        border-bottom-right-radius: rem(15);
    }
}

// Theme colors
#fem-smd.dark-theme-section,
#fem-smd {

    &:before {
        background-color: var(--very-dark-blue-top-bg);
    }

    .card {
        background-color: var(--dark-desaturated-blue-card-bg);

        &:hover {
            background-color: var(--dark-desaturated-blue-card-bg-hover);
            cursor: pointer;
        }
    }
}

#fem-smd.light-theme-section {
    background-color: var(--white-bg);
    color: var(--very-dark-blue-text);

    .followers-count {
        color: var(--dark-grayish-blue-text);
    }

    &:before {
        background-color: var(--very-pale-blue-top-bg);
    }

    .card {
        background-color: var(--light-grayish-blue-card);
      
        &:hover {
            background-color: var(--light-grayish-blue-card-hover);
            cursor: pointer;
        }

        .card-big-top p {
            color: var(--dark-grayish-blue-text);
        }

        .views-label {
            color: var(--dark-grayish-blue-text);
        }
    }
}

// Cards general
.card {
    border-radius: rem(5);
    padding: rem(25);
    position: relative;

    .views img {
        width: rem(8);
        height: rem(5);
        margin-right: rem(4);
    }
    .views.down img {
        margin-top: rem(1);
    }
}

// Section 1
.section-totals {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(30);
    padding-top: rem(60);
    
    @include breakpoint(large) {
        grid-template-columns: repeat(4, 1fr);
    }
    
    @include breakpoint-down(medium) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @include breakpoint-down(small) {
        grid-template-columns: repeat(1, 1fr);
    }

    .card-big {
        text-align: center;

        @include breakpoint-down(medium) {
            grid-template-columns: repeat(2, 1fr);
            width: auto;
        }

        @include breakpoint-down(small) {
            margin: 0 auto;
            width: 100%;
        }

        &.facebook {
            border-top: rem(5) solid var(--facebook);
        }

        &.twitter {
            border-top: rem(5) solid var(--twitter); 
        }

        &.instagram {
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: rem(5);
                background: linear-gradient( 225deg, var(--instagram-end) 0%, var(--instagram-start) 98.02% ); 
            }
        }

        &.youtube {
            border-top: rem(5) solid var(--youtube);
        }

        .card-big-top {
            display: inline-flex;

            img {
                align-self: center;
                margin-right: rem(5);
            }

            p {
                margin: 0;
                font-size: rem(12);
                align-self: center;
                line-height: 1;
                font-weight: 700;
                color: var(--desaturated-blue-text);
            }
        }

        .card-big-middle {
            padding-top: rem(20);

            .count-big {
                font-size: rem(46);
                font-weight: 700;
            }

            p {
                margin: 0;
                text-transform: uppercase;
                letter-spacing: rem(5);
                font-size: rem(10);
                color: var(--desaturated-blue-text);
            }
        }

        .card-big-bottom {
            display: inline-flex;
            padding-top: rem(20);
            font-size: rem(12);

            img {
                align-self: center;
                margin-right: rem(3); 
            }

            p {
                font-weight: 700;
            }

            &.up {
                color: var(--limegreen);

                img {
                    fill: var(--limegreen);
                }
            }

            &.down {
                color: var(--brightred);

                img {
                    fill: var(--brightred);
                }
            }
        }
    }
}

// Section 2
.section-daily {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(30);

    @include breakpoint-down(medium) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }

    @include breakpoint-down(small) {
        grid-template-columns: repeat(1, 1fr);
    }
    
    .card-small {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: rem(20);
        flex-wrap: wrap;
        height: rem(124);

        @include breakpoint-down(medium) {
            width: 100%;
            margin: 0;
        }

        .views-percentage,
        .page-view {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                margin: 0;
            }
        }

        .page-view {

            .views-label {
                font-size: rem(13);
                color: var(--desaturated-blue-text);
                font-weight: 700;
            }

            p {
                font-size: rem(32);
                font-weight: 700;
                line-height: 0.9;
            }
        }

        .views-percentage {

            > img {
                width: rem(20);
                align-self: end;
            }

            .views {
                display: flex;
                font-size: rem(12);
                font-weight: 700;

                &.up {
                    color: var(--limegreen);  
                }

                &.down {
                    color: var(--brightred); 
                }

                img {
                    align-self: center;
                }
            }
        }
    }
}