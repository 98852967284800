html {
    font-size: 100%;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-inter);
}