@use '../util/' as *;

.toggle-title {
    font-size: rem(13);
    line-height: 100%;
    display: flex;
    align-self: center;
    padding-right: 7px;
    font-weight: 700;
    color: var(--desaturated-blue-text);
}

.toggle-wrapper {
    position: relative;
    display: inline-block;
    width: rem(52);
    height: rem(25);
    
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .toggle {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: rem(34);
        background: linear-gradient( 225deg, var(--toggle-light-end) 0%, var(--toggle-light-start) 98.02% );
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: rem(20);
            width: rem(20);
            left: rem(3);
            top: rem(2.5); 
            border-radius: 50%;
            background-color: var(--very-dark-blue-bg);
            -webkit-transition:all .4s ease-in-out;
            transition:all .4s ease-in-out;
        }
    }

    input:checked + .toggle:before {
        -webkit-transform: translateX(rem(26));
        -ms-transform: translateX(rem(26));
        transform: translateX(rem(26));
    }
}


input:checked + .toggle:before {
    background: var(--white-bg);
}

input:checked + .toggle {
    background: linear-gradient( 225deg, var(--toggle) 0%, var(--toggle) 98.02% );
}