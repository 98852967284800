// Using CSS preprocessors so they can be easily manipulated vis javaScript

:root {

    --limegreen: hsl(163, 72%, 41%);
    --brightred: hsl(356, 69%, 56%);
    --facebook: hsl(208, 92%, 53%);
    --twitter: hsl(203, 89%, 53%);
    --instagram-start:  hsl(37, 97%, 70%);
    --instagram-end: hsl(329, 70%, 58%);
    --youtube: hsl(348, 97%, 39%);
    --white-bg: hsl(0, 0%, 100%);
    --very-pale-blue-top-bg: hsl(225, 100%, 98%);
    --light-grayish-blue-card: hsl(227, 47%, 96%);
    --light-grayish-blue-card-hover: hsl(231, 25%, 90%);
    --dark-grayish-blue-text: hsl(228, 12%, 44%);
    --very-dark-blue-text: hsl(230, 17%, 14%);
    --light-card-bg: hsl(0.62 0.50 0.96);
    --very-dark-blue-bg: hsl(230, 17%, 14%);
    --very-dark-blue-top-bg: hsl(232, 19%, 15%);
    --dark-desaturated-blue-card-bg: hsl(228, 28%, 20%);
    --dark-desaturated-blue-card-bg-hover: hsl(229, 28%, 27%);
    --desaturated-blue-text: hsl(228, 34%, 66%);
    --white-text: hsl(0, 0%, 100%);
    --toggle: hsl(230, 22%, 74%);
    --toggle-light-start: hsl(210, 78%, 56%);
    --toggle-light-end: hsl(146, 68%, 55%);

    // Counter colors
    --counter-grayish-blue: hsl(237, 18%, 59%);
    --counter-soft-red: hsl(345, 95%, 68%);
    --counter-white: hsl(0, 0%, 100%);
    --counter-dark-desaturated-blue: hsl(236, 21%, 26%);
    --counter-very-dark-blue: hsl(235, 16%, 14%);
    --counter-very-dark-black-blue: hsl(234, 17%, 12%);

    --counter-shadow-blue: hsl(235, 20%, 32%);
    
}