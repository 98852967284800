@use 'globals';
@use 'components';
@use 'util' as *;


#fem-smd {
    padding: rem(40) rem(15);
    background-color: var(--very-dark-blue-bg);
    color: var(--white-text);
}

.section-heading {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoint-down(small) {
       display: block;

        &__col-left {
            border-bottom: rem(1) solid var(--dark-grayish-blue-text);
            padding-bottom: rem(20);
        }

        &__col-right {
            display: flex;
            justify-content: space-between;
        }
    }

     h1 {
        margin-bottom: 0;
    }

    .followers-count {
        font-size: rem(13);
        color: var(--desaturated-blue-text);
        font-weight: 700;
    }

    .section-heading__col-right {
        display: flex;

        @include breakpoint-down(small) {
            margin-top: 20px;
        }
    }
}

.sub-title {
    margin-top: rem(60);

    h2 {
        margin-bottom: rem(10);
    }
}

.credits {
    margin-top: rem(60);
    font-size: 14px;
    line-height: 1;
    color: var(--desaturated-blue-text);

    p {
        margin: rem(10) 0;
    }
}